@import "bootstrap";
@import "~@angular/cdk/overlay-prebuilt";

@import "variables";

.loading-placeholder {
    position: relative;
    overflow: hidden;
    background-color: $gray-100;
    &::after {
        content: "";
        display: block;
        background-color: $gray-200;
        opacity: 0.3;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        transform: translateX(0);
        animation: 1.5s loading-placeholder ease-in-out infinite;
    }
}

@keyframes loading-placeholder {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}

img[data-zoom] {
    cursor: crosshair;
}
.vsf-zoom-zoom-pane {
    box-shadow: $box-shadow-lg;
}

.rounded {
    border-radius: 8px;
}
